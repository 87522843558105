<template>
  <div style="padding: 1%">
    <el-empty :image-size="200" v-show="!isview" v-loading="loading"></el-empty>
    <div v-show="isview" style="width: 100%" v-loading="loading">
      <el-descriptions
        class="margin-top"
        title="企业往来对账单"
        :column="isPC ? 4 : 1"
        border
        :size="isPC ? 'medium ' : 'small'"
      >
        <el-descriptions-item label="公司名称">{{
          info.CompanyName
        }}</el-descriptions-item>
        <el-descriptions-item label="客户名称">{{
          info.CusName
        }}</el-descriptions-item>
        <el-descriptions-item label="对账单日期">{{
          moment(info.DzTime).format("YYYY-MM-DD")
        }}</el-descriptions-item>
        <el-descriptions-item label="对账单单号">{{
          info.DzBild
        }}</el-descriptions-item>
        <el-descriptions-item label="销售员">{{
          info.Sale
        }}</el-descriptions-item>
        <el-descriptions-item label="客户代码">{{
          info.CusCode
        }}</el-descriptions-item>
        <el-descriptions-item label="账款截止日期" :span="2">{{
          moment(info.EndTime).format("YYYY-MM-DD")
        }}</el-descriptions-item>
        <el-descriptions-item label="销售员电话">{{
          info.SalePhone
        }}</el-descriptions-item>
        <el-descriptions-item label="联系人（采购）">{{
          info.Procure
        }}</el-descriptions-item>
      </el-descriptions>
      <div style="width: 100%; text-align: center; line-height: 25px">
        感谢贵司对我司的支持与认可！为使贵我双方业务往来数据清晰无误，例行对账，明细如下:
      </div>
      <el-table
        :data="Tabledata"
        border
        style="width: 100%"
        :max-height="isPC ? 550 : 300"
        size="mini"
      >
        <el-table-column
          v-for="(im, ix) in columns"
          :key="ix"
          :prop="im.dataIndex"
          :label="im.title"
          :fixed="im.fixed"
          :width="im.width || '150'"
          :align="im.align || 'center'"
          :formatter="im.formatter"
        >
        </el-table-column>
      </el-table>
      <!-- text-align: right; margin-bottom: 10px; margin-top: 10px -->
      <el-pagination
        style="text-align: right; margin-bottom: 10px; margin-top: 10px"
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="Pageindex"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="PageRow"
        :pager-count="5"
        :layout="
          isPC ? 'total, sizes, prev, pager, next, jumper' : 'prev, pager, next'
        "
        :total="total"
      >
      </el-pagination>
      <el-descriptions
        class="margin-top"
        :column="isPC ? 3 : 1"
        border
        :size="isPC ? 'medium ' : 'small'"
      >
        <el-descriptions-item label="已开票未付款金额：">{{
          toMoney(info.InvoicedButAmount)
        }}</el-descriptions-item>
        <el-descriptions-item label="已发货未开票金额：">{{
          toMoney(info.ShippedButAmount)
        }}</el-descriptions-item>
        <el-descriptions-item label="期末应收金额合计：">{{
          toMoney(info.EndingAmount)
        }}</el-descriptions-item>
      </el-descriptions>
      <div style="height: 50px"></div>
      <el-dialog title="确认对账函" :visible="UpVisible" width="80%">
        <div style="display: flex; width: 100%; flex-wrap: wrap">
          <div
            style="
              display: flex;
              justify-content: center;
              width: 50%;
              margin-bottom: 15px;
            "
            v-for="(item, index) in imgList"
            :key="index"
          >
            <el-image
              style="width: 100px; height: 100px"
              :src="item.url"
              :preview-src-list="[item.url]"
            >
            </el-image>
          </div>

          <div
            style="
              display: flex;
              justify-content: center;
              width: 50%;
              margin-bottom: 15px;
            "
          >
            <el-button
              icon="el-icon-plus"
              style="width: 100px; height: 100px"
              @click="openCamera"
            >
            </el-button>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="UpVisible = false">取 消</el-button>
          <el-button type="primary" @click="UpVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <div
        style="
          position: fixed;
          bottom: 0px;
          right: 20px;
          z-index: 999;
          width: 100%;
          height: 50px;
          background-color: #fff;
          text-align: right;
        "
      >
        <el-button
          type="primary"
          @click="dialogVisibleCustomerPhone = true"
          v-if="isshare"
          >短信分享</el-button
        >
        <el-button type="primary" @click="dialogVisible = true" v-if="isshare"
          >微信分享</el-button
        >
        <el-button type="primary" @click="UpVisible = true" v-if="!isshare"
          >确认</el-button
        >
        <!-- <el-button type="danger " v-if="isshare">拒收</el-button> -->
      </div>
    </div>
    <el-dialog
      title="请选择对账日期"
      :visible.sync="centerDialogVisible"
      width="40%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
    >
      <el-date-picker
        style="width: 50%; margin-left: 25%"
        v-model="time"
        type="date"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
      >
      </el-date-picker>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getdataList">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="请使用微信扫一扫"
      :visible.sync="dialogVisible"
      width="300px"
      :before-close="
        () => {
          dialogVisible = false;
        }
      "
    >
      <div style="display: flex; justify-content: center">
        <vue-qr
          :logoSrc="require('@/assets/logo.png')"
          :text="codeValue"
          :logoScale="0.2"
          :size="200"
        ></vue-qr>
      </div>
    </el-dialog>
    <el-dialog
      title="发送对账单"
      :visible.sync="dialogVisibleCustomerPhone"
      width="30%"
      :before-close="
        () => {
          this.dialogVisibleCustomerPhone = false;
        }
      "
      append-to-body
    >
      <el-input
        v-model="CustomerPhone"
        placeholder="请输入客户手机号"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCustomerPhone = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirmCustomerPhone"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import wx from "weixin-js-sdk";
import moment from "moment";
export default {
  components: {
    VueQr,
  },
  computed: {},
  data() {
    return {
      dialogVisibleCustomerPhone: false,
      CustomerPhone: "",
      UpVisible: false,
      dialogVisible: false,
      moment,
      innerWidth: 0,
      isPC: false,
      codeValue: "",
      centerDialogVisible: true,
      isview: false,
      loading: false,
      time: "",
      info: {},
      Pageindex: 1,
      PageRow: 50,
      total: 0,
      columns: [
        {
          title: "日期",
          dataIndex: "time",
          width: 100,
          formatter: (row, column, cellValue, index) => {
            return moment(cellValue).format("YYYY-MM-DD");
          },
        },
        { title: "HY合同号", dataIndex: "HYContractcode", align: "right" },
        { title: "客户合同号", dataIndex: "CusContractcode" },
        { title: "产品名称", dataIndex: "ProductName" },
        { title: "规格型号", dataIndex: "Specification" },
        { title: "客户商品名称", dataIndex: "CusProductName" },
        { title: "单位", dataIndex: "Unname" },
        {
          title: "合同数量",
          dataIndex: "ContractNums",
          align: "right",
          formatter: (row, column, cellValue, index) => {
            return Number(cellValue).toFixed(2);
          },
        },
        {
          title: "实发数量",
          dataIndex: "IssuedNums",
          align: "right",
          formatter: (row, column, cellValue, index) => {
            return Number(cellValue).toFixed(2);
          },
        },
        {
          title: "销售单价",
          dataIndex: "Price",
          align: "right",
          formatter: (row, column, cellValue, index) => {
            return this.toMoney(cellValue);
          },
        },
        {
          title: "销售金额",
          dataIndex: "SalesAmount",
          align: "right",
          formatter: (row, column, cellValue, index) => {
            return this.toMoney(cellValue);
          },
        },
        {
          title: "已开票金额",
          dataIndex: "InvoicingAmount",
          align: "right",
          formatter: (row, column, cellValue, index) => {
            return this.toMoney(cellValue);
          },
        },
        {
          title: "未开票金额",
          dataIndex: "NotInvoicingAmount",
          align: "right",
          formatter: (row, column, cellValue, index) => {
            return this.toMoney(cellValue);
          },
        },
        {
          title: "已付款金额",
          dataIndex: "PaymentAmount",
          align: "right",
          formatter: (row, column, cellValue, index) => {
            return this.toMoney(cellValue);
          },
        },
        {
          title: "未付款金额",
          dataIndex: "NotPaymentAmount",
          align: "right",
          formatter: (row, column, cellValue, index) => {
            return this.toMoney(cellValue);
          },
        },
        { title: "货物接收人", dataIndex: "GoodsRecipient" },
        { title: "发票接收人", dataIndex: "InvoiceRecipient" },
        { title: "发票号码", dataIndex: "InvoiceCodes" },
        { title: "备注", dataIndex: "Reemarks" },
      ],
      Tabledata: [],
      isshare: false,
      imgList: [],
    };
  },
  created() {
    this.getShareInfo();
    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }
    if (this.$route.query.bcid) {
      this.bcid = this.$route.query.bcid;
    }
    this.isshare = false;
    if (this.$route.query.time && this.$route.query.bcid) {
      this.centerDialogVisible = false;
      this.time = this.$route.query.time;
      this.isshare = true;
      this.getdataList();
    }
  },
  watch: {
    innerWidth(v) {
      console.log(v);
      if (v < 500) {
        this.isPC = false;
      } else {
        this.isPC = true;
      }
    },
  },
  mounted() {
    this.innerWidth = window?.innerWidth;
    if (this.innerWidth < 500) {
      this.isPC = false;
    } else {
      this.isPC = true;
    }
    window.addEventListener("resize", () => {
      this.innerWidth = window?.innerWidth;
      if (this.innerWidth < 500) {
        this.isPC = false;
      } else {
        this.isPC = true;
      }
    });
  },

  methods: {
    dialogConfirmCustomerPhone() {
      this.dialogVisibleCustomerPhone = false;
    },
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://hzq.yoojet.com/"
      );
      let arr = ticketData.Data.split(",");
      const config = {
        appId: "wxde354ac424a66adc",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };

      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["updateAppMessageShareData"],
      });

      wx.ready(() => {
        wx.updateAppMessageShareData({
          title: "对账函", // 分享标题
          desc: "感谢贵司对我司的支持与认可！为使贵我双方业务往来数据清晰无误，例行对账", // 分享描述
          link: "https://hzq.yoojet.com", // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: "https://hzq.yoojet.com/logo200.png", // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
    },
    openCamera() {
      const input = document.createElement("input");
      // 设置input类型为file，accept属性限制只能选择图像文件
      input.type = "file";
      input.accept = "image/*";
      // 添加change事件处理函数
      input.addEventListener("change", (event) => {
        if (!event.target.files || event.target.files.length === 0) {
          console.log("未选择任何文件");
          return;
        }
        // 获取第一张选定的图片文件对象
        const file = event.target.files[0];
        // 创建FileReader对象
        const reader = new FileReader();
        // 当读取完成时触发load事件
        reader.onload = () => {
          this.image = reader.result; // 更新预览图片地址
          // TODO: 这里可以根据需要将图片发送到服务器进行保存等操作
          let form = new FormData();
          //选择的图片添加到formdata里面
          form.append("file", file);
          this.$http
            .uploadFiles("/Base_Manage/Upload/UploadFileUserByForm", form)
            .then((res) => {
              console.log(res.data);
              this.imgList.push(res.data);
            });
        };
        // 读取图片文件内容
        reader.readAsDataURL(file);
      });
      // 模拟点击input元素，弹出系统默认的文件选择对话框
      input.dispatchEvent(new MouseEvent("click"));
    },
    //将数字转换成金额显示
    toMoney(num) {
      if (isNaN(num)) {
        return num;
      }
      num = typeof num == "string" ? parseFloat(num) : num; //判断是否是字符串如果是字符串转成数字
      num = num.toFixed(2); //保留两位
      num = parseFloat(num); //转成数字
      num = num.toLocaleString(); //转成金额显示模式
      //判断是否有小数
      if (num.indexOf(".") == -1) {
        num = num + ".00";
      } else {
        num = num.split(".")[1].length < 2 ? num + "0" : num;
      }
      return num; //返回的是具有千分位格式并保留2位小数的字符串
    },
    handleCurrentChange(val) {
      this.Pageindex = val;
      this.getdataList();
    },
    handleSizeChange(val) {
      this.PageRow = val;
      this.getdataList();
    },
    getdataList() {
      console.log(this.time);
      if (this.time) {
        let baseUrl = location.href.split("#")[0];
        this.codeValue =
          baseUrl +
          `#/ReconciliationCopy?bcid=${this.$route.query.bcid}&time=${this.time}`;

        this.centerDialogVisible = false;

        this.loading = true;

        this.$http
          .npost(
            `/Base_Manage/DZ_Contract_Close/GetReconciliation?CusCode=${this.bcid}&EndTime=${this.time}&Pageindex=${this.Pageindex}&PageRow=${this.PageRow}`,
            {}
          )
          .then((val) => {
            let res = val.data;
            console.log(res);
            this.isview = true;
            this.loading = false;
            if (res.Success && res.Data) {
              this.info = res.Data;
              this.Tabledata = res.Data.ReconciliatioDetails;
              this.total = res.Data.Total;
            } else {
              this.isview = false;
              this.$message.error("请求失败");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error("请选择对账日期");
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-descriptions__header {
  justify-content: center;
}
</style>